var Bm = window.Bm || {}

Bm.common = {

  init: function() {

    this.smoothScroll();
    this.navToggle();
    this.alertToggle();
    this.watchScroll();
    this.tabsInit();

    // hero slideshows
    if($('.hero > .swiper-container').length) {
      var mySwiper = new Swiper ('.hero > .swiper-container', {
        speed: 600,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        effect: 'fade',
        loop: true
      });
    }

    // beer page
    if($('.beer-carousel > .swiper-container').length) {
      this.holdMyBeer();
    }
  },

  // animation between pages - add load state and splash page init
  // body classes: is-loading, is-loaded, is-unloading
  pageOnLoad: function(event) {
    var breakpoint = window.matchMedia('(max-width:1023px)');
    var $body = $('body');
    var $splash = $('.splash');
    var $hero = $('.hero--main');

    // all pages
    $('body').removeClass('is-loading').addClass('is-loaded');

    console.log('window loaded: .is-loaded');

    // splash page - disable on mobile
    if($splash.length && breakpoint.matches === true) {
      $body.addClass('is-splash-complete');
      return false;
    }

    console.log('window loaded: splash inialized...');

    // splash page init
    if($splash.length) {
      $body.addClass('is-splash-animating');

      $hero[0].addEventListener('animationstart', function(e) {
        if(e.animationName == 'splashSlideOut') {
          $body.addClass('is-splash-animating-out');
        }
      });

      $splash[0].addEventListener('animationend', function(e) {
        if(e.animationName == 'splashTimeline') {
          $body.removeClass('is-splash-animating is-splash-animating-out').addClass('is-splash-complete');
        }
      });
    }
  },

  // animation between pages - add unload state
  // body classes: is-loading, is-loaded, is-unloading
  pageBeforeUnload: function(event) {
    $('body').addClass('is-unloading');

    if($('body').hasClass('beer')) {
      $('.beer-container').addClass('animate-out');
    }
  },

  holdMyBeer: function() {
    var breakpoint = window.matchMedia('(max-width:1023px)');
    var mySwiper;

    var checkBreakpoint = function() {
      if(breakpoint.matches === true) {
        // disable swiper on small screens
        if (mySwiper !== undefined) {
          mySwiper.destroy(true, true);
        }
        // hide large screen headings
        $('[data-beer-heading]').addClass('d-none').removeClass('is-active');
        return;
      } else {
        // enable on larger screens
        return enableSwiper();
      }
    };

    var enableSwiper = function() {
      mySwiper = new Swiper ('.beer-carousel > .swiper-container', {
        direction: 'horizontal',
        effect: 'slide',
        speed: 400,
        autoplay: false,
        loop: false,
        keyboard: {
          enabled: true
        },
        slidesPerView: 'auto',
        breakpoints: {
          1024: {
            slidesOffsetBefore: 110
          },
          1460: {
            slidesOffsetBefore: 278
          }
        },
        on: {
          init: handleSlideChange,
          slideChangeTransitionStart: handleSlideChange,
          resize: handleSlideChange
        }
      });
    };

    // Swiper slide change callback
    var handleSlideChange = function() {
      var $elem = $(this.el).find('.swiper-slide-active');
      var group = $elem.attr('data-beer-group');
      initBeers(group, this);
    };

    // Toggle display state
    var initBeers = function(group, mySwiper) {
      var $heading = $('[data-beer-heading="'+group+'"]');

      if($heading.length) {
        $('[data-beer-heading]').not($heading).addClass('d-none').removeClass('is-active');
        $heading.removeClass('d-none').addClass('is-active');
      }

      mySwiper.slidesGrid.map(function(val, i) {
        var containerWidth = mySwiper.size - mySwiper.params.slidesOffsetBefore;
        var slideWidth = mySwiper.slidesSizesGrid[0];
        var left = 0-mySwiper.translate;
        var right = containerWidth - mySwiper.translate - slideWidth;

        var visible = (val >= left && val <= right);

        $('.swiper-slide').eq(i).toggleClass('is-active', visible);
      });

      $('.swiper-slide').removeClass('is-inactive');
      $('.swiper-slide').not('[data-beer-group="'+group+'"]').addClass('is-inactive');

      if(mySwiper.isBeginning) {
        $('.swiper-button-prev').attr('aria-disabled', 'true');
      } else if (mySwiper.isEnd) {
        $('.swiper-button-next').attr('aria-disabled', 'true');
      } else {
        $('.swiper-button-prev, .swiper-button-next').attr('aria-disabled', 'false');
      }
    };

    // Custom prev/next handling
    $('.swiper-button-next, .swiper-button-prev').on('click', function() {
      var direction = ($(this).hasClass('swiper-button-prev')) ? 'prev' : 'next';
      var group = $('.swiper-slide-active').attr('data-beer-group');
      var slidesPerGroup = 1;

      if (mySwiper.size >= 1024) {
        slidesPerGroup = 2;
      }

      if (mySwiper.size >= 1280) {
        slidesPerGroup = 3;
      }

      if (mySwiper.size >= 1800) {
        slidesPerGroup: 4;
      }

      if(direction === 'prev') {
        var prev = mySwiper.activeIndex - slidesPerGroup;

        if(prev < 0) {
          prev = 0;
        }

        mySwiper.slideTo(prev);
      }

      if(direction === 'next') {
        var next = mySwiper.activeIndex + slidesPerGroup;

        if(next > mySwiper.slides.length-1) {
          next = mySwiper.slides.length-1;
        }

        // Check for end of group and just advance to first
        // slide of next group instead of multiple slides.
        var nextGroup = $('.swiper-slide').eq(next).attr('data-beer-group');

        if(nextGroup !== group) {
          var $firstInGroup = $('.swiper-slide[data-beer-group="'+nextGroup+'"]').first();
          next = $('.swiper-slide').index($firstInGroup);
        }

        mySwiper.slideTo(next);
      }
    });

    // Beer group nav, go to slide or scroll to anchor
    $('.beer-nav a').on('click', function(e){
      var href = $(this).attr('href');

      console.log('click', href);

      if(href !== '#' && $(href).length) {
        e.preventDefault();

        if(mySwiper !== undefined) {
          var slideIndex = $('.swiper-slide').index($(href).next());

          if(slideIndex !== -1) {
            mySwiper.slideTo(slideIndex);
          }
        } else {
          Bm.common.scrollTo(href, -30);
        }
      }
    });

    // Beer can hover triggers
    $('.beer-can').on('click touchend', function(e) {
      e.preventDefault();
      $(this).parent('.beer').toggleClass('is-hovering');
    }).on('mouseenter', function(e) {
      $(this).parent('.beer').addClass('is-hovering');
    });

    $('.beer').on('mouseleave', function(e) {
      $(this).removeClass('is-hovering');
    });

    // Add breakpoint listener and initialize
    breakpoint.addListener(checkBreakpoint);
    checkBreakpoint();
  },

  watchScroll:function(e) {
    // fixed position pinned elements
    var watchers = $('[data-pin]').map(function(i, element) {

      var watcher = scrollMonitor.create( element );

      watcher.lock();

      watcher.stateChange(function() {
        $(element).toggleClass('is-fixed', this.isAboveViewport);
      });

      return watcher;
    });

    // footer trigger points
    var $footer = $('.site-footer');
    var $html = $('html');

    var footerWatch = scrollMonitor.create($footer[0]);

    footerWatch.stateChange(function() {
      $html.toggleClass('is-footer-scrolled', this.isAboveViewport);
      $('.site-footer .page-up').toggleClass('is-fixed', this.isAboveViewport);
    });

    var footerWatchThreshold = scrollMonitor.create($footer[0], 250);

    footerWatchThreshold.stateChange(function() {
      $html.toggleClass('is-footer-scrolling', this.isAboveViewport);
    });

    // global scroll animation helpers
    $(window).on('scroll', _.throttle(function() {
      var scrollTop = (window.scrollY || document.documentElement.scrollTop);
      var viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
      var threshold = 104;

      $html.toggleClass('is-scrolling', (scrollTop >= threshold));
      $html.toggleClass('is-scrolled', (scrollTop >= viewportHeight));
    }));
  },

  scrollTo: function(elem, offset) {
    var offset = offset || 0;
    $('html, body').animate({
      scrollTop: $(elem).offset().top + offset
    }, 600);
  },

  smoothScroll: function() {
    $('[data-scrollto]').on('click', function(e){
      var href = $(this).attr('href');

      if(href !== '#' && $(href).length) {
        e.preventDefault();
        Bm.common.scrollTo(href);
      }
    });
  },

  alertToggle: function() {
    $('.alert-toggle').on('click', function(e) {
      e.preventDefault();

      var $html = $('html');
      var $alert = $('.alert-container');
      var isOpen = ($html.hasClass('is-alert-active') || $html.hasClass('is-alert-hovering'));

      $alert.attr('aria-expanded', !isOpen ? 'true' : 'false');
      $html.removeClass('is-alert-hovering');
      $html.toggleClass('is-alert-active', !isOpen);
    })
    .on('touchend', function(e) {
      // prevent simulated mouse events
      e.preventDefault();
      $(e.target).trigger('click');
    })
    .on('mouseenter', function(e) {
      $('html').addClass('is-alert-hovering');
    });

    $('.site-alert').on('mouseleave', function(e) {
      $('html').removeClass('is-alert-hovering');
    });
  },

  navToggle: function() {
    $('.nav-toggle').on('click', function(e) {
      e.preventDefault();

      var $html = $('html');
      var $menu = $('.site-menu');
      var isOpen = ($html.hasClass('is-menu-active') || $html.hasClass('is-menu-hovering'));

      $menu.attr('aria-expanded', !isOpen ? 'true' : 'false');
      $html.removeClass('is-menu-hovering');
      $html.toggleClass('is-menu-active', !isOpen);
    })
    .on('touchend', function(e) {
      // prevent simulated mouse events
      e.preventDefault();
      $(e.target).trigger('click');
    })
    .on('mouseenter', function(e) {
      $('html').addClass('is-menu-hovering');
    });

    $('.nav-main').on('mouseleave', function(e) {
      $('html').removeClass('is-menu-hovering');
    });
  },

  tabsInit: function() {
    $('.tabs__tablist a').on('click', function(e) {
      e.preventDefault();

      var $tab = $(this);
      var $pane = $($tab.attr('href'));

      if($tab.hasClass('show') || !$pane.length) {
        return;
      }

      $('.tabs__tablist a').attr('aria-selected', 'false');
      $tab.attr('aria-selected', 'true');

      $('.tabs__pane').removeClass('show active');
      $pane.addClass('show active');
    });
  }

};

(function($) {

  $(document).ready(function() {

    Bm.common.init();

  });

  window.addEventListener("load", Bm.common.pageOnLoad);
  window.addEventListener("beforeunload", Bm.common.pageBeforeUnload);

})(jQuery);
